import type { WPTerm } from "@/types/wordpress"
import Link from "next/link"
import cx from "classnames"

type Props = {
  terms: WPTerm[]
  className?: string
  link?: boolean
}

export function Terms({ terms, className, link }: Props) {
  if (!terms?.length) {
    return null
  }

  return (
    <div className={cx("flex flex-wrap mb-2 text-sm", className)}>
      {[...terms].slice(0, 1).map((term) =>
        link ? (
          <Link key={term.uri} href={term.uri}>
            <a className="px-2 py-1 mr-4 bg-gray text-blue last:mr-0">
              {term.name}
            </a>
          </Link>
        ) : (
          <span
            key={term.uri}
            className="px-2 py-1 mr-4 bg-gray text-blue last:mr-0">
            {term.name}
          </span>
        )
      )}
    </div>
  )
}
