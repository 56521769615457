export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_NewsCards {
  fieldGroupName
  contentType
  heading
  background
  visibility
  link {
    target
    title
    url
  }
  listNews {
    total
    endCursor
    posts {
      ... on Post {
        id
        uri
        title
        date
        featuredImage {
          node {
            sourceUrl
            altText
            mediaDetails {
              height
              width
            }
          }
        }
        categories {
          nodes {
            slug
            name
            uri
          }
        }
      }
    }
  }
  postObject {
    ... on Post {
      id
      title
      uri
      date
      featuredImage {
        node {
          sourceUrl
          altText
          mediaDetails {
              height
              width
          }
        }
      }
      categories {
        nodes {
          slug
          name
          uri
        }
      }
    }
  }
}
`
