import type { WPPost } from "@/types/wordpress"
import parse from "html-react-parser"
import Link from "next/link"
import { CardImage } from "../post/cardImage"
import { Terms } from "../post/terms"
import cx from "classnames"

export type Props = {
  post: WPPost
  showExcerpt?: boolean
  showImage?: boolean
  className?: string
}

export function Post({ post, showImage, showExcerpt, className }: Props) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  const date = new Date(post.date)
  const mIndex = date.getMonth()
  const m = months[mIndex]
  const d = date.getDate()
  const y = date.getFullYear()
  const fixedDate = `${m} ${d}, ${y} `

  return (
    <article
      className={cx(
        "flex flex-col justify-between col-span-1 p-4 sm:p-6 xl:p-8 bg-white text-gray-600 border border-gray-border h-full",
        { className }
      )}>
      <header>
        {showImage && <CardImage image={post.featuredImage} />}

        {post.categories?.nodes && <Terms terms={post.categories?.nodes} />}
        <h4>
          <Link href={post.uri}>
            <a>{post.title && parse(post.title)}</a>
          </Link>
        </h4>
      </header>

      {showExcerpt && post.excerpt ? (
        <div className="mt-2 line-clamp-4">{parse(post.excerpt)}</div>
      ) : null}
      {post.date && (
        <footer className="mt-1 text-sm text-blue xl:mt-5">{fixedDate}</footer>
      )}
    </article>
  )
}
