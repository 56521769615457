import { Post } from "@/components/cards/post"
import SectionHeader from "@/components/SectionHeader"
import { Component, Container } from "@/styledComponents/content"
import type { WPPost } from "@/types/wordpress"
export { QUERY } from "./query"

export type TNewsCards = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_NewsCards"
  heading: string
  contentType: "posts_by_cat" | "pick_posts"
  link: {
    url: string
  }
  listNews: {
    total: number
    endCursor: string
    posts: WPPost[]
  }
  postObject: WPPost[]
  background: string
  visibility?: string
}

export default function NewsCards({
  heading,
  contentType,
  listNews,
  link,
  postObject,
  background
}: TNewsCards): JSX.Element {
  let posts
  if (contentType === "posts_by_cat") {
    posts = listNews.posts
  } else {
    posts = postObject
  }

  return (
    <Component bgColor={background}>
      <SectionHeader title={heading} link={link} />
      <Container className="grid gap-4 sm:grid-cols-2 sm:gap-8 md:gap-x-7 lg:gap-10 xl:gap-12 lg:grid-cols-4">
        {posts &&
          posts.length > 0 &&
          posts.map((post) => {
            return <Post key={post.id} post={post} />
          })}
      </Container>
    </Component>
  )
}
