import type { TACFLink } from "@/types/wordpress"
import cx from "classnames"
import ACFLink from "./ACFLink"
import { Container } from "./styled/content"
import parse from "html-react-parser"
import type { ReactNode } from "react"

type Props = {
  title?: string
  link?: TACFLink
  children?: ReactNode
  centerText?: boolean
  narrow?: boolean
}

const SectionHeader = ({
  title,
  link,
  centerText,
  children,
  narrow
}: Props) => {
  if (!title && !link && !centerText && !children) {
    return null
  }
  return (
    <Container
      narrow={narrow}
      className={cx(
        "sm:mb-4 md:mb-6 lg:mb-8 items-center flex-wrap grid grid-cols-12 sm:gap-8",
        {
          "justify-center text-center": centerText,
          "justify-between": title && !centerText,
          "justify-end": !title && !centerText,
          "gap-y-3 mb-4": link,
          "gap-y-4 mb-6": !link
        }
      )}>
      <div
        className={cx({
          "col-span-12 lg:col-span-11 max-w-2xl mx-auto relative lg:-right-12":
            centerText,
          "col-span-12 sm:col-span-8 md:col-span-8 lg:col-span-6": !centerText
        })}>
        {title && <h2>{parse(title)}</h2>}
      </div>

      {link && (
        <div
          className={cx(
            "grid sm:col-end-13 sm:place-self-end sm:place-items-end",
            {
              "col-span-1": centerText,
              "col-span-4": !centerText
            }
          )}>
          <ACFLink
            styleType="link"
            link={link}
            className="font-normal sm:text-base xl:text-lg"
          />
          {children}
        </div>
      )}
    </Container>
  )
}

export default SectionHeader
